import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import Image from "../../../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from "moment";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { calculatePSLFinalDiscount } from "../../../../../../../../utils/calculatePrice";
import { Spin } from "antd";
import { BsDownload } from "react-icons/bs";
import { download_crm_order_detail } from "../../../../../../../../api/endpoints/orders";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step2OrderDetail({ stepData, handleBackStep }) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";

			default:
				return;
		}
	};

	const columns = [
		{
			title: (algo) => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.DarkGrey }} />
					</div>
				);
			},
			dataIndex: "productPhotoUrl",
			key: "productPhotoUrl",
			width: 80,
			align: "center",
			render: (value, record, index) => (
				<Image width={40} height={40} src={value} alt={record.productTitle} />
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Producto
				</div>
			),
			dataIndex: "productTitle",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Ean
				</div>
			),
			width: 140,
			dataIndex: "productEan13",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cantidad
				</div>
			),
			dataIndex: "quantity",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Subtotal
				</div>
			),
			dataIndex: "price",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Ahorro
				</div>
			),
			dataIndex: "productDiscount",
			render: (value, record) => (
				<Typo level={7} type="muted">
					$
					{numberWithDotAndCommas(
						(parseFloat(record.price) - parseFloat(record.offerPrice)).toFixed(
							2
						)
					)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Total
				</div>
			),
			dataIndex: "offerPrice",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					% PSL Final
				</div>
			),
			dataIndex: "discount",
			width: 120,
			render: (value, record) => (
				<Typo level={7} type="muted">
					{numberWithDotAndCommas(
						calculatePSLFinalDiscount(
							record?.offerPrice,
							record?.price,
							record?.clientGeneralDiscount
						).toFixed(2)
					)}{" "}
					%
				</Typo>
			),
		},
	];

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _orderData = [
				{
					trf: stepData?.orderNumId,
					pointOfSaleName: stepData?.pointOfSaleName,
					pointOfSaleAddress: stepData?.pointOfSaleAddress,
					distributorName: stepData?.distributorName,
					orderDate: stepData?.orderDate,
					orderQtyItems: stepData?.orderQtyItems,
					orderQtyUnits: stepData?.orderQtyUnits,
					orderSubtotal: stepData?.orderSubtotal,
					orderTotal: stepData?.orderTotal,
					orderDiscount: stepData?.orderDiscount,
					orderProvince: stepData?.orderProvince,
					orderLocality: stepData?.orderLocality,
					orderState: stepData?.orderState,
				},
			];

			const _orderProducts = stepData?.productList?.map((p) => ({
				productTitle: p.productTitle,
				productEan13: p.productEan13,
				quantity: p.quantity,
				price: p.price,
				offerPrice: p.offerPrice,
				finalDiscount: calculatePSLFinalDiscount(
					p.offerPrice,
					p.price,
					p.clientGeneralDiscount
				).toFixed(2),
			}));

			const res = await download_crm_order_detail({
				orderToExport: _orderData,
				orderProductsToExport: _orderProducts,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Ficha_Orden_" +
				stepData?.orderNumId +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer>
				<Section>
					<Section
						onClick={() => handleBackStep(1)}
						style={{ cursor: "pointer", justifyContent: "start" }}
					>
						<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
						<Typo type="secondary" level={7}>
							Volver
						</Typo>
					</Section>
					<Section>
						<Typo type="dark" level={6}>
							Detalle pedido #{stepData?.orderNumId}
						</Typo>
					</Section>
					<Section>
						{loading ? (
							<Spin size="small" />
						) : (
							<CustomAntdTooltip
								placement="left"
								width="105px"
								tooltipContent={[
									{
										body: "Exportar datos",
									},
								]}
							>
								<BsDownload
									size={18}
									color={COLORS.Secondary}
									style={{ cursor: "pointer" }}
									onClick={handleDownloadFile}
								/>
							</CustomAntdTooltip>
						)}
					</Section>
				</Section>
				<Section style={{ justifyContent: "start", gap: 100 }}>
					<Container>
						<Section>
							<Typo type="grey" level={7}>
								# TRF:
							</Typo>
							<Typo type="grey" level={7}>
								{stepData?.orderNumId}
							</Typo>
						</Section>
						<Section>
							<Typo type="grey" level={7}>
								Fecha orden:
							</Typo>
							<Typo type="mediumGrey" level={7}>
								{moment(stepData?.orderDate).format("DD-MM-YYYY")}
							</Typo>
						</Section>
					</Container>
					<Container>
						<Section>
							<Typo type="grey" level={7}>
								Punto de venta:
							</Typo>
							<Typo type="mediumGrey" level={7}>
								{stepData?.pointOfSaleName}
							</Typo>
						</Section>
						<Section>
							<Typo type="grey" level={7}>
								Dirección entrega:
							</Typo>
							<Typo type="mediumGrey" level={7}>
								{stepData?.pointOfSaleAddress}
							</Typo>
						</Section>
					</Container>
					<Container>
						<Section>
							<Typo type="grey" level={7}>
								Droguería:
							</Typo>
							<Typo type="mediumGrey" level={7}>
								{stepData?.distributorName}
							</Typo>
						</Section>
						<Section>
							<Typo type="grey" level={7}>
								Estado:
							</Typo>
							<Typo type="mediumGrey" level={7}>
								{selectStateToShow(stepData?.orderState)}
							</Typo>
						</Section>
					</Container>
				</Section>
			</HeaderContainer>
			<BodyContainer>
				<AntdTable
					dataSource={stepData?.productList || []}
					loading={!stepData}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BodyContainer>
		</StepContainer>
	);
}
