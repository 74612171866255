import React, { useEffect, useState } from "react";
import {
	check_new_prices,
	export_prices,
	get_products,
	get_products_header_info,
	update_prices,
} from "../../../../../../api/endpoints/products";
import moment from "moment";
import {
	Buttons,
	UploadFile,
	AntdButton,
	TableContainer,
	AntdTable,
	ButtonsLeft,
	ConfirmButton,
	SpanCointainer,
	Span,
} from "./styles";
import { DatePicker, Input, Modal } from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { COLORS, Typo } from "../../../../../layout/theme";
import { useNavigate } from "react-router-dom";
import { get_initial_percentage } from "../../../../../../api/endpoints/clients";
import { calculatePrice } from "../../../../../../utils/calculatePrice";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { useAuth } from "../../../../../../contexts/authContext";
import LoadingSkeleton from "./components/LoadingSkeleton";

export default function UpdatePrices({ clientId = null }) {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [data, setData] = useState(false);
	const [state, setState] = useState("PUBLISHED");
	const [hasChanges, setHasChanges] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [initialPercentage, setInitialPercentage] = useState(null);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);

	const { confirm } = Modal;

	const fetch_prices_today = async () => {
		const res = await get_products("", state, clientId);
		const newData = res
			.map((i) => ({
				title: i.title,
				price: i.price,
				offerPrice: i.offerPrice,
				ean13: i.ean13,
				discount: i.discount,
				minQuantity: i.minQuantity,
				offerFrom: i.offerFrom ? moment(i.offerFrom, "YYYY-MM-DD") : null,
				offerTo: i.offerTo ? moment(i.offerTo, "YYYY-MM-DD") : null,
				triggerDiscount: i.triggerDiscount,
				triggerQty: i.triggerQty,
				hasChanges: false,
			}))
			.sort((a, b) =>
				a.title.toUpperCase() > b.title.toUpperCase()
					? 1
					: b.title.toUpperCase() > a.title.toUpperCase()
					? -1
					: 0
			);
		setData(newData);
	};

	const fetch_products_header = async () => {
		const res = await get_products_header_info(clientId);
		setHeaderInfo(res);
	};

	const fetch_initial_percentage = async () => {
		const res = await get_initial_percentage();
		setInitialPercentage(res);
	};

	useEffect(() => {
		fetch_prices_today();
		fetch_products_header();
		fetch_initial_percentage();
	}, [state]);

	const onInputChange = (key, index) => (e) => {
		const newData = [...data];
		if (key === "offerFrom" || key === "offerTo") {
			newData[index][key] = e;
		} else {
			newData[index][key] = Number(e.target.value);
		}

		if (key === "discount") {
			newData[index]["offerPrice"] = calculatePrice(
				newData[index]["price"],
				initialPercentage,
				Number(e.target.value),
				0,
				0
			);
		}
		if (key === "price") {
			newData[index]["offerPrice"] = calculatePrice(
				Number(e.target.value),
				initialPercentage,
				newData[index]["discount"],
				0,
				0
			);
		}

		newData[index]["hasChanges"] = true;
		setData(newData);
		setHasChanges(true);
	};

	const fetch_export_prices = async () => {
		setLoading(true);
		try {
			const res = await export_prices(clientId);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName = moment().format("DD-MM-YYYY") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const handleUpload = async (e) => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("data", clientId ?? userdata.clientId);
			formData.append("file", e.fileList[e.fileList.length - 1].originFileObj);
			const res = await check_new_prices(formData);
			setData(
				res.productsToChange.map((i) => ({
					...i,
					offerFrom: i.offerFrom ? moment(i.offerFrom, "YYYY-MM-DD") : null,
					offerTo: i.offerTo ? moment(i.offerTo, "YYYY-MM-DD") : null,
				}))
			);
			const thereIsChanges = res.productsToChange.some(
				(i) => i.hasChanges === true
			);
			setHasChanges(thereIsChanges);
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los precios no pudieron actualizarse",
				"Verifique su archivo de excel, si sigue con problemas contacte con soporte a soporte@onetransfer.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = () => {
		confirm({
			title: "¿Seguro que quiere publicar estos precios?",
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La publicación de los precios se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				confirmPrices();
			},
		});
	};

	const confirmPrices = async () => {
		setLoading(true);
		try {
			const res = await update_prices({ prices: data, clientId: clientId });
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Los precios se actualizaron correctamente",
				"Los precios se actualizaron correctamente"
			);
			fetch_prices_today();
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Los precios no se pudieron correctamente, contacte con soporte a soporte@onetransfer.com",
				"Los precios se se pudieron correctamente, contacte con soporte a soporte@onetransfer.com"
			);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean13</div>,
			dataIndex: "ean13",
			render: (value, record) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{value}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>$ Precio</div>,
			dataIndex: "price",
			width: 130,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						onChange={onInputChange(
							"price",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						//addonBefore="$"
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>$ Precio Oferta</div>,
			dataIndex: "offerPrice",
			width: 130,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={
							record.discount !== 0 && record.hasChanges === true
								? calculatePrice(
										record.price,
										initialPercentage,
										record.discount,
										0,
										0
								  )
								: value
						}
						disabled
						//addonBefore="$"
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>% Desc.</div>,
			dataIndex: "discount",
			width: 100,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						onChange={onInputChange(
							"discount",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						//addonBefore="%"
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Min. un.</div>,
			dataIndex: "minQuantity",
			width: 90,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						min={1}
						onChange={onInputChange(
							"minQuantity",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Oferta desde</div>,
			dataIndex: "offerFrom",
			width: 150,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<DatePicker
						style={{
							width: "100%",
						}}
						onChange={onInputChange(
							"offerFrom",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						//disabledDate={disabledDate}
						value={value}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Oferta hasta</div>,
			dataIndex: "offerTo",
			width: 150,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<DatePicker
						style={{
							width: "100%",
						}}
						onChange={onInputChange(
							"offerTo",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						//disabledDate={disabledDate}
						value={value}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Un. desc.</div>,
			dataIndex: "triggerQty",
			width: 100,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						onChange={onInputChange(
							"triggerQty",
							page === 1 ? index : (page - 1) * 25 + index
						)}
					/>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>% desc un.</div>,
			dataIndex: "triggerDiscount",
			width: 100,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,
						fontWeight: "600",
					}}
				>
					<Input
						type="number"
						value={value}
						onChange={onInputChange(
							"triggerDiscount",
							page === 1 ? index : (page - 1) * 25 + index
						)}
						//addonBefore="%"
					/>
				</div>
			),
		},
	];

	// const handleBackToTheGuide = () => {
	// 	updateStartGuideProgress(user.uid, 3);
	// 	navigate("/startGuide");
	// };

	return (
		<>
			<Buttons clientId={clientId}>
				<ButtonsLeft>
					<AntdButton onClick={fetch_export_prices} loading={loading}>
						Exportar
					</AntdButton>
					<UploadFile
						multiple={false}
						showUploadList={false}
						beforeUpload={() => false}
						action={null}
						onChange={handleUpload}
						loading={loading}
					>
						Importar
					</UploadFile>
					{clientId && (
						<ConfirmButton clientId={clientId}>
							{hasChanges && (
								<AntdButton onClick={handleSubmit} success loading={loading}>
									Confirmar cambios
								</AntdButton>
							)}
						</ConfirmButton>
					)}
				</ButtonsLeft>
			</Buttons>
			{!clientId && (
				<ConfirmButton>
					{hasChanges && (
						<AntdButton onClick={handleSubmit} success loading={loading}>
							Confirmar cambios
						</AntdButton>
					)}
				</ConfirmButton>
			)}
			<SpanCointainer clientId={clientId}>
				<Span onClick={() => setState("PUBLISHED")}>
					Publicados ({headerInfo?.totalPublished})
				</Span>
				<Span onClick={() => setState("DRAFT")}>
					Borradores ({headerInfo?.totalDraft})
				</Span>
				{!clientId && (
					<Span
						onClick={() => navigate("/admin/items")}
						style={{ textDecoration: "underline" }}
					>
						Productos
					</Span>
				)}
				{/* {!clientId && userdata?.guideProgress === 2 && (
					<SignInTemplate.AntdButton
						type="primary"
						bg="Primary"
						color="White"
						icon={<QuestionCircleOutlined />}
						onClick={handleBackToTheGuide}
						style={{
							width: "250px",
							fontWeight: "400",
						}}
					>
						Volver a la Guia de Inicio
					</SignInTemplate.AntdButton>
				)} */}
			</SpanCointainer>
			{!data ? (
				<LoadingSkeleton />
			) : (
				<TableContainer>
					<AntdTable
						dataSource={data}
						columns={columns}
						loading={loading}
						pagination={{
							pageSize: 25,
							showSizeChanger: false,
							onChange: (page) => setPage(page),
						}}
						rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
						// scroll={{
						// 	x: 1500,
						// 	y: 450,
						// }}
					/>
				</TableContainer>
			)}
		</>
	);
}
