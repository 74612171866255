import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	AntdButton,
	ButtonContainer,
	Container,
	Inner,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import {
	CalendarOutlined,
	SettingOutlined,
	UserOutlined,
} from "@ant-design/icons";
import {
	MdOutlineBrandingWatermark,
	MdOutlineBusiness,
	MdOutlineCheck,
	MdOutlineChecklist,
	MdOutlineComputer,
	MdOutlineLocalOffer,
	MdOutlinePlace,
	MdOutlineSettings,
	MdOutlineShoppingBag,
} from "react-icons/md";
import {
	TbDeviceMobile,
	TbDiscount,
	TbShoppingCartDiscount,
	TbShoppingCartOff,
} from "react-icons/tb";
import { FaFileInvoiceDollar, FaHeadset, FaPlus } from "react-icons/fa";
import { BiCoinStack, BiPlusMedical } from "react-icons/bi";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";
import ModuleMarketplace from "./components/module-marketplace/ModuleMarketplace";
import ModuleErp from "./components/module-erp/ModuleErp";

const { Title, Paragraph } = Typography;

export default function GeneralConfig({ userdata, clientId, clientContract }) {
	const { generalConfigLab, setGeneralConfigLab } = useFilter();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Configuración General
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
				<Container>
					<Section>
						<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
							Configuración General
						</Title>
						<Paragraph>
							<blockquote>
								En esta sección podrás configurar el sistema
							</blockquote>
						</Paragraph>
					</Section>
					<ButtonContainer>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={180}
							height={48}
							fontSize={12}
							onClick={() => setGeneralConfigLab(CONSTANTS.MODULE_ERP)}
							$active={generalConfigLab === CONSTANTS.MODULE_ERP}
						>
							ERP
						</AntdButton>
						<AntdButton
							type="primary"
							bg="Primary"
							color="White"
							style={{ fontWeight: "600", marginLeft: "20px" }}
							width={180}
							height={48}
							fontSize={12}
							onClick={() => setGeneralConfigLab(CONSTANTS.MODULE_MARKETPLACE)}
							$active={generalConfigLab === CONSTANTS.MODULE_MARKETPLACE}
						>
							MARKETPLACE
						</AntdButton>
					</ButtonContainer>
				</Container>
			</Main.Header>

			<Inner>
				{clientContract &&
					(clientContract?.activeMobileApp ||
						clientContract?.activeExpoSection ||
						clientContract?.activeWebSeller) &&
					generalConfigLab === CONSTANTS.MODULE_ERP && (
						<ModuleErp clientContract={clientContract} />
					)}
				{generalConfigLab === CONSTANTS.MODULE_MARKETPLACE && (
					<ModuleMarketplace clientContract={clientContract} />
				)}
			</Inner>
		</Main>
	);
}
