import React, { useEffect, useState } from "react";
import Main from "../../../layout/main/Main";
import { Link, useSearchParams } from "react-router-dom";
import {
	ButtonContainer,
	ChildrenContainer,
	AntdButton,
	SelectContainer,
	Section,
	Container,
} from "./styles";
import { Select } from "antd";
import { Typo } from "../../../layout/theme";
import { get_clients_users } from "../../../../api/endpoints/clients";
import UsersList from "./list/UsersList";
import AntdSelect from "../../../common/antd-select/AntdSelect";
import { CONSTANTS } from "../../../../utils/constants";
import { useFilter } from "../../../../contexts/filterContext";

export default function MarketUsersPage() {
	const { marketUsersType, setMarketUsersType } = useFilter();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [clientType, setClientType] = useState(null);
	const [data, setData] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [clientOptions, setClientOptions] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const clientTypes = [
		{
			id: 1,
			name: "Laboratorio",
			value: "LABORATORIO",
		},
		{
			id: 2,
			name: "Farmacia",
			value: "FARMACIA",
		},
		{
			id: 3,
			name: "One Transfer",
			value: "ONETRANSFER",
		},
	];

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_clients_users();
			setData(res);
		};
		setLoading(true);
		fetch_clients();
		setLoading(false);
	}, []);

	const handleClientTypeSelect = (value) => {
		setSelectedClient(null);
		switch (value) {
			case "LABORATORIO":
				setClientType(
					data
						?.filter((c) => !c.isOneMarket && c.isSeller && c.isApproved)
						.sort((a, b) => a.title - b.title)
				);
				setClientOptions(
					data
						?.filter((c) => !c.isOneMarket && c.isSeller && c.isApproved)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;
			case "FARMACIA":
				setClientType(
					data
						?.filter((c) => !c.isOneMarket && !c.isSeller)
						.sort((a, b) => a.title - b.title)
				);
				setClientOptions(
					data
						?.filter((c) => !c.isOneMarket && !c.isSeller)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;
			case "ONETRANSFER":
				setClientType(data?.filter((c) => c.isOneMarket));
				setClientOptions(
					data
						?.filter((c) => c.isOneMarket)
						.sort((a, b) => a.title - b.title)
						.map((r) => ({
							value: r.id,
							label: r.name,
						}))
				);
				break;

			default:
				break;
		}
	};

	const handleClientSelect = (value) => {
		setSelectedClient(clientType.filter((c) => c.id === value));
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{" "}
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Usuarios</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<SelectContainer style={{ flexBasis: 0 }}>
					<Container>
						<Section>
							<Typo type="primary" level={6}>
								Tipo de Cuenta
							</Typo>
							<AntdSelect
								onChange={handleClientTypeSelect}
								style={{ width: 160 }}
								loading={loading || !data}
								disabled={!data}
							>
								{clientTypes?.map((item) => (
									<Select.Option key={item.id} value={item.value}>
										{item.name}
									</Select.Option>
								))}
							</AntdSelect>
						</Section>
						<Section>
							{clientType && (
								<Typo type="primary" level={6}>
									Cuentas
								</Typo>
							)}
							{clientType && (
								<Select
									showSearch
									placeholder="Elegi un cliente"
									optionFilterProp="children"
									onChange={handleClientSelect}
									value={selectedClient !== "null" ? selectedClient : "..."}
									filterOption={(input, option) =>
										(option?.label ?? "")
											.toLowerCase()
											.includes(input.toLowerCase())
									}
									options={clientOptions}
									style={{ width: 180 }}
								/>
							)}
						</Section>
					</Container>
					{selectedClient && (
						<ButtonContainer>
							<AntdButton
								type="primary"
								bg="Primary"
								color="White"
								style={{ fontWeight: "600", marginLeft: "20px" }}
								width={220}
								height={48}
								fontSize={12}
								onClick={() => setMarketUsersType(CONSTANTS.USERS_WEB)}
								$active={marketUsersType === CONSTANTS.USERS_WEB}
							>
								Usuarios Marketplace
							</AntdButton>
							{(selectedClient[0].contract?.activeMobileApp ||
								selectedClient[0].isOneMarket) && (
								<AntdButton
									type="primary"
									bg="Primary"
									color="White"
									style={{ fontWeight: "600", marginLeft: "20px" }}
									width={220}
									height={48}
									fontSize={12}
									onClick={() => setMarketUsersType(CONSTANTS.USERS_MOBILE)}
									$active={marketUsersType === CONSTANTS.USERS_MOBILE}
								>
									Usuarios ERP
								</AntdButton>
							)}
						</ButtonContainer>
					)}
				</SelectContainer>
				{selectedClient && (
					<ChildrenContainer>
						{marketUsersType === CONSTANTS.USERS_WEB && (
							<UsersList
								usersData={selectedClient[0].users}
								clientId={selectedClient[0].id}
								client={selectedClient[0]}
								userType="WEB"
							/>
						)}
						{marketUsersType === CONSTANTS.USERS_MOBILE && (
							<UsersList
								usersData={selectedClient[0].users}
								clientId={selectedClient[0].id}
								client={selectedClient[0]}
								userType="MOBILE"
							/>
						)}
					</ChildrenContainer>
				)}
			</Main.Body>
		</Main>
	);
}
