import React, { useEffect, useMemo } from "react";
import {
	AntdInput,
	AntdTable,
	BodyContainer,
	CompaginationDiv,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
	TextContainer,
} from "./styles";
import AntdSearch from "../../../../../../../common/antd-search/AntdSearch";
import debounce from "lodash/debounce";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, Select, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../../../utils/constants";

export default function Step1GeneralPosList({
	setKey,
	generalPosList,
	selectedProvince,
	handleSelectProvince,
	provinces,
	handleDeleteFilters,
	isLoading,
	searchInfo,
	handleDecrement,
	handlePageChange,
	handleIncrement,
	paginationInputValue,
	setPaginationInputValue,
	handleChangeStep,
	generalPosSearch,
	setGeneralPosSearch,
	generalPosCondition,
	setGeneralPosCondition,
	headerInfo,
}) {
	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Punto de venta
				</div>
			),
			width: 250,
			dataIndex: "name",
			render: (value, record) => (
				<TextContainer
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(record?.id, CONSTANTS.MODULE_GENERAL_POS_DETAILS)
					}
					pointer
				>
					<Typo level={6} type="primary">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cuit
				</div>
			),
			width: 140,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Provincia
				</div>
			),
			width: 120,
			dataIndex: "province",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Localidad
				</div>
			),
			width: 120,
			dataIndex: "locality",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Dirección
				</div>
			),
			width: 200,
			dataIndex: "address",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Código Postal
				</div>
			),
			width: 100,
			dataIndex: "postalCode",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
	];

	const eventHandler = (e) => {
		setGeneralPosSearch(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 1600), []);

	return (
		<StepContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Sucursal
						</Typo>
						<AntdSearch
							allowClear
							placeholder={isLoading ? "Cargando..." : "Buscar sucursal"}
							width={300}
							onChange={debouncedEventHandler}
							style={{ height: 31, borderRadius: 1 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder={isLoading ? <Spin size="small" /> : "Provincia"}
							optionFilterProp="children"
							value={selectedProvince}
							onChange={(e, o) => handleSelectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button
						type="primary"
						onClick={handleDeleteFilters}
						disabled={isLoading}
					>
						Borrar filtros
					</Button>
				</Container>
			</HeaderContainer>
			<HeaderContainer style={{ justifyContent: "space-evenly" }}>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta totales:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setGeneralPosCondition(
										CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ALL
									)
								}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalPos}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta activos:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setGeneralPosCondition(
										CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ASSIGNED
									)
								}
								style={{ display: "flex" }}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalActive}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="grey" level={6}>
							Puntos de venta inactivos:
						</Typo>
						{isLoading ? (
							<Spin size="small" />
						) : (
							<Section
								onClick={() =>
									setGeneralPosCondition(
										CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_UNASSIGNED
									)
								}
							>
								<Typo type="secondary" level={5}>
									{headerInfo?.totalInactive}
								</Typo>
							</Section>
						)}
					</FilterContainer>
				</Container>
			</HeaderContainer>
			{(generalPosCondition || generalPosSearch || selectedProvince) && (
				<Container>
					<Typo type="grey" level={7}>
						{generalPosCondition === CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ALL
							? "Puntos de venta totales"
							: generalPosCondition ===
							  CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_ASSIGNED
							? "Puntos de venta activos"
							: generalPosCondition ===
							  CONSTANTS.ERP_MODULE_PHARMACY_ROUTE_UNASSIGNED
							? "Puntos de venta inactivos"
							: "Puntos de venta"}
					</Typo>
				</Container>
			)}
			{(generalPosCondition || generalPosSearch || selectedProvince) && (
				<BodyContainer>
					<AntdTable
						dataSource={generalPosList}
						loading={isLoading}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
					{searchInfo && searchInfo.totalPos > 0 && (
						<CompaginationDiv>
							{searchInfo.actualPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							<AntdInput
								step={1}
								min={1}
								max={searchInfo.totalPages}
								onPressEnter={(e) => handlePageChange(e)}
								onChange={(e) =>
									setPaginationInputValue(e.target.value.toString())
								}
								defaultValue={paginationInputValue.toString()}
								value={paginationInputValue.toString()}
								addonAfter={`/ ${searchInfo.totalPages}`}
								style={{
									width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
								}}
							/>
							{searchInfo.actualPage < searchInfo.totalPages && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={
										searchInfo.actualPage === searchInfo.totalPages
											? true
											: false
									}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
				</BodyContainer>
			)}
		</StepContainer>
	);
}
