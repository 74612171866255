import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import {
	MdCampaign,
	MdFormatListBulleted,
	MdMonetizationOn,
	MdMoney,
	MdMoneyOffCsred,
	MdOutlineBrandingWatermark,
	MdOutlineChecklist,
	MdOutlineComputer,
	MdOutlineLocalOffer,
	MdOutlineMonetizationOn,
	MdOutlineMoney,
	MdOutlineShoppingBag,
} from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import { BiImport, BiPhotoAlbum, BiPlusMedical } from "react-icons/bi";
import {
	DollarOutlined,
	SnippetsOutlined,
	StarOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { FaFireAlt } from "react-icons/fa";
import { CONSTANTS } from "../../../../../../utils/constants";
import { TbShoppingCartDiscount } from "react-icons/tb";
import { useFilter } from "../../../../../../contexts/filterContext";

export default function ModuleMarketplace({ clientContract }) {
	const { setAdminUsersType } = useFilter();
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineComputer style={{ color: COLORS.Secondary }} size={50} />
					<Typo type="Grey" level={4}>
						Marketplace
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra los datos del marketplace
					</Typo>
				</Container>
				<StyledUl>
					<Link to="/homeMarketplace">
						<StyledLi>
							<MdOutlineComputer color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Marketplace
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/account?uri=generalConfig">
						<StyledLi>
							<MdOutlineBrandingWatermark color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Cuenta
							</Typo>
						</StyledLi>
					</Link>
					<Link
						to="/admin/users?uri=generalConfig"
						onClick={() => setAdminUsersType(CONSTANTS.USERS_WEB)}
					>
						<StyledLi>
							<UserOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Usuarios
							</Typo>
						</StyledLi>
					</Link>
					<StyledLi
						onClick={() =>
							setShowSubMenu(CONSTANTS.MODULE_MARKETPLACE_PRODUCTS)
						}
						style={{ cursor: "pointer" }}
					>
						<MdOutlineChecklist color={COLORS.Secondary} />
						<Typo type="secondary" level={13}>
							Productos
						</Typo>
					</StyledLi>
					<StyledLi
						onClick={() =>
							setShowSubMenu(CONSTANTS.MODULE_MARKETPLACE_PUBLICITY)
						}
						style={{ cursor: "pointer" }}
					>
						<MdOutlineMonetizationOn color={COLORS.Secondary} />
						<Typo type="secondary" level={13}>
							Publicidad
						</Typo>
					</StyledLi>
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_MARKETPLACE_PRODUCTS && (
				<ModuleContainer>
					<Container>
						<MdOutlineChecklist style={{ color: COLORS.Secondary }} size={50} />
						<Typo type="Grey" level={4}>
							Productos
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar los productos del marketplace
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/items?uri=generalConfig">
							<StyledLi>
								<MdOutlineChecklist color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Productos activos
								</Typo>
							</StyledLi>
						</Link>
						<Link to="/admin/prices?uri=generalConfig">
							<StyledLi>
								<TbShoppingCartDiscount color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Ofertas marketplace
								</Typo>
							</StyledLi>
						</Link>
					</StyledUl>
				</ModuleContainer>
			)}
			{showSubMenu === CONSTANTS.MODULE_MARKETPLACE_PUBLICITY && (
				<ModuleContainer>
					<Container>
						<MdOutlineMonetizationOn
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Publicidad
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar la publicidad de tu negocio
						</Typo>
					</Container>
					<StyledUl>
						<Link to="/admin/store?uri=generalConfig">
							<StyledLi>
								<MdOutlineShoppingBag color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Banners
								</Typo>
							</StyledLi>
						</Link>
						{clientContract && clientContract.activeCouponSection && (
							<Link to="/admin/laboratoryCoupons?uri=generalConfig">
								<StyledLi>
									<MdOutlineLocalOffer color={COLORS.Secondary} />
									<Typo type="secondary" level={13}>
										Cupones
									</Typo>
								</StyledLi>
							</Link>
						)}
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}
