import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS, Typo } from "../../../../layout/theme";
import {
	ButtonContainer,
	CartDelete,
	CompaginationDiv,
	Container,
	ContainerTitle,
	Inner,
	ModalContainer,
	Section,
} from "./styles";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import { Button, Modal, Space } from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	RightCircleOutlined,
	RightOutlined,
} from "@ant-design/icons";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Image from "../../../../common/image/Image";
import { get_simplified_products_list } from "../../../../../api/endpoints/products";
import debounce from "lodash/debounce";
import ProductsTable from "../components/products-table/ProductsTable";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_offer_featureds,
	get_product_for_featureds,
	update_offer_featureds,
} from "../../../../../api/endpoints/search";
import LoadingSkeleton from "../components/loading-skeleton/LoadingSkeleton";
import AntdTableDragDropGeneral from "../../../../common/and-table-dragdrop-general/AntTableDragDropGeneral";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../utils/constants";
let productIndex = null;

export default function FeaturedOffers() {
	const navigate = useNavigate();
	const [loadingPage, setLoadingPage] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [searchByStore, setSearchByStore] = useState(null);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(50);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [offerProducts, setOfferProducts] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (productIndex) {
			setData(data?.filter((p, idx) => p.id !== productIndex[0]?.id));
		}
	}, [productIndex]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	useEffect(() => {
		setLoadingPage(true);
		fetch_featureds();
	}, []);

	const fetch_featureds = async () => {
		const res = await get_offer_featureds();
		setOfferProducts(
			res.offerProducts?.map((p, idx) => ({
				key: p.id,
				id: p.id,
				productId: p.id,
				quantity: 1,
				clientName: p.clientName,
				title: p.name,
				photoUrl: p.photoUrl,
				presentationName: p.presentationName,
				index: idx,
				price: p.price,
				offerPrice: p.offerPrice,
			}))
		);
		setLoadingPage(false);
	};

	const productColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			width: 100,
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image width={40} height={40} src={value} alt={record.title} />
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (t, item) => (
				<ContainerTitle>
					<Typo
						type="primary"
						lineHeight={1}
						texto="medium"
						fontSize={t.length < 15 ? 18 : 16}
					>
						{t}
					</Typo>
					{"  "}
					<Typo type="muted" lineHeight={1} level={5}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) => (
				<Typo type="success" lineHeight={1} level={5}>
					{`${numberWithDotAndCommas(
						(100 - (item.offerPrice * 100) / item.price).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Agregar</div>,
			width: 100,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button
						type="primary"
						ghost
						onClick={() => {
							handleAddToTemplate(index);
						}}
					>
						<RightCircleOutlined />
					</Button>
				</Space>
			),
		},
	];

	const featuredsColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Foto</div>,
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 90,
			render: (photoUrl) => <Image src={photoUrl} height={40} width={40} />,
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			key: "clientName",
			width: 150,
			render: (value, item) => (
				<Typo type="muted" lineHeight={1} level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			width: 600,
			render: (t, item) => (
				<ContainerTitle>
					<Typo type="primary" lineHeight={1} fontSize={14}>
						{t}
					</Typo>
					<Typo type="muted" lineHeight={1} level={5} fontSize={14}>
						{item?.presentationName}
					</Typo>
				</ContainerTitle>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "discount",
			key: "discount",
			render: (t, item) => (
				<Typo type="success" lineHeight={1} level={5}>
					{`${numberWithDotAndCommas(
						(100 - (item.offerPrice * 100) / item.price).toFixed(2)
					)}%`}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Quitar</div>,
			key: "action",
			render: (_, record, index) => (
				<Space size="middle">
					<Button type="link" onClick={() => handleDelete(index)}>
						<CartDelete>X</CartDelete>
					</Button>
				</Space>
			),
		},
	];

	const fetch_products = async (search, skipValue) => {
		setLoading(true);
		try {
			const res = await get_simplified_products_list(
				search,
				"",
				skipValue !== null ? skipValue : skip,
				take
			);
			setData(
				res.map((r) => ({
					...r,
					quantity: 1,
					key: r.id,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
			setSearchModalShow(true);
		}
	};

	//////******************************************************** */

	const handleAddToTemplate = async (e, q) => {
		const _template = data?.filter((p, idx) => idx === e);
		productIndex = _template;
		const auxTemplate = offerProducts.concat(_template);

		const TemplateSinDuplicados = auxTemplate.reduce(
			(acumulador, valorActual) => {
				const elementoYaExiste = acumulador.find(
					(elemento) => elemento.id === valorActual.id
				);
				if (elementoYaExiste) {
					return acumulador.map((elemento) => {
						if (elemento.id === valorActual.id) {
							return {
								...elemento,
								quantity: elemento.quantity + valorActual.quantity,
							};
						}
						return elemento;
					});
				}

				return [...acumulador, valorActual];
			},
			[]
		);
		setOfferProducts(
			TemplateSinDuplicados.sort((a, b) => b.offerIndex - a.offerIndex)
		);
	};

	//////******************************************************** */

	const handleDelete = (index) => {
		const _template = offerProducts.filter((p, idx) => idx !== index);
		setOfferProducts(_template);
	};

	//////******************************************************** */

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido completados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				navigate("/");
			},
		});
	};

	const eventHandler = (e) => {
		setCurrentPage(1);
		setSearchByStore(e.target.value);
		fetch_products(e.target.value, 0);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 650), []);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 50;

			fetch_products(searchByStore, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 50);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 50;

		fetch_products(searchByStore, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 50);
	};

	const handleModalOk = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const handleModalCancel = () => {
		setSearchByStore(null);
		setSearchModalShow(false);
	};

	const onFinish = async (values) => {
		try {
			if (offerProducts.length === 0) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"Debe seleccionar al menos un Producto para destacar en ofertas"
				);
				return;
			} else if (offerProducts.length > 60) {
				setSubmitting(false);
				openNotificationWithIcon(
					TYPE.ERROR,
					"La cantidad de Productos para destacar en ofertas no puede ser mayor a 60"
				);
				return;
			}

			setSubmitting(true);

			const postData = {
				featureds: {
					offerProducts:
						offerProducts?.map((element, idx) => {
							return { id: element?.id, index: idx };
						}) || [],
				},
			};

			await update_offer_featureds(postData);
			window.scrollTo(0, 0);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Destacados creados con exito",
				"Los destacados se crearon con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al crear destacados",
				"Un error ocurrio al intentar crear los destacados, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleSuggested = async (filterType) => {
		try {
			setSubmitting(true);

			const result = await get_product_for_featureds(filterType);
			window.scrollTo(0, 0);

			setOfferProducts(
				result?.possibleOfferProducts?.map((p, idx) => ({
					key: p.id,
					id: p.id,
					productId: p.id,
					quantity: 1,
					clientName: p.clientName,
					title: p.name,
					photoUrl: p.photoUrl,
					presentationName: p.presentationName,
					index: idx,
					price: p.price,
					offerPrice: p.offerPrice,
				}))
			);

			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Sugeridos obtenidos con exito",
				"Los sugeridos se cargaron con exito"
			);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al generar sugeridos",
				"Un error ocurrio al intentar generar los sugeridos, contacte con soporte a soporte@onetransfer.com."
			);
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	if (loadingPage) return <LoadingSkeleton />;

	return (
		<Inner>
			<Container>
				<Section>
					<Typo type="primary" level={6}>
						Productos destacados en ofertas
					</Typo>
					<AntdSearch
						allowClear
						placeholder="Buscar producto"
						width={459}
						onChange={(e) => setSearchByStore(e.target.value)}
						value={searchByStore}
						onPressEnter={(e) => debouncedEventHandler(e)}
						disabled={loading}
					/>
				</Section>
				{/* <SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={() =>
						handleSuggested(CONSTANTS.PRODUCTS_FOR_FEATUREDS_SALES)
					}
					loading={submitting}
				>
					Top 60 mayores ventas ($)
				</SignInTemplate.AntdButton> */}
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={() =>
						handleSuggested(CONSTANTS.PRODUCTS_FOR_FEATUREDS_UNITS)
					}
					loading={submitting}
				>
					Top 60 mayores ventas (Un.)
				</SignInTemplate.AntdButton>
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={() =>
						handleSuggested(CONSTANTS.PRODUCTS_FOR_FEATUREDS_ORDERS)
					}
					loading={submitting}
				>
					Top 60 mayores ventas (Ordenes)
				</SignInTemplate.AntdButton>
			</Container>
			<Modal
				open={searchModalShow}
				onOk={handleModalOk}
				onCancel={handleModalCancel}
				width={900}
				centered={true}
				footer={null}
				destroyOnClose={true}
			>
				<ModalContainer>
					{data && data.length > 0 && (
						<CompaginationDiv>
							{currentPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							{currentPage}
							{data.length === 50 && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={data.length < 50 ? true : false}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
					<ProductsTable
						data={data}
						columns={productColumns}
						loading={loading}
						scrollData={{ y: 350 }}
					/>
				</ModalContainer>
			</Modal>
			<AntdTableDragDropGeneral
				data={offerProducts}
				setData={setOfferProducts}
				columns={featuredsColumns}
				scroll={{ y: 700 }}
			/>
			<ButtonContainer>
				<SignInTemplate.AntdButton
					type="Primary"
					color="white"
					bg="Primary"
					onClick={onFinish}
					loading={submitting}
				>
					Guardar cambios
				</SignInTemplate.AntdButton>
				<SignInTemplate.AntdButton
					type="Primary"
					loading={submitting}
					onClick={handleBack}
				>
					<Typo type="danger" level={6}>
						Salir sin guardar
					</Typo>
				</SignInTemplate.AntdButton>
			</ButtonContainer>
		</Inner>
	);
}
