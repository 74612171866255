import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../../layout/main/Main";
import { Space, Menu, Dropdown, Modal, Button } from "antd";
import {
	get_products_header_info,
	get_products_page,
	update_masive_product_state,
	update_product_state,
} from "../../../../../../api/endpoints/products";
import {
	Filters,
	SpanCointainer,
	Span,
	Price,
	Line,
	OfferPrice,
	FiltersLeft,
	UnP,
	ActionButton,
	ArrowRight,
	Arrow,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../../common/image/Image";
import DataTable from "../../../../../common/data-table/DataTable";
import { FaArrowLeft, FaArrowRight, FaImages } from "react-icons/fa";
import COLORS from "../../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Typo } from "../../../../../layout/theme";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	CloseCircleOutlined,
	InfoCircleOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import debounce from "lodash/debounce";
import ProductModal from "../../../../../common/product-modal/ProductModal";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { useAuth } from "../../../../../../contexts/authContext";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";

const { confirm } = Modal;

export default function ProductList({ uri }) {
	const navigate = useNavigate();
	const { user, userdata, updateStartGuideProgress } = useAuth();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("PUBLISHED");
	const [key, setKey] = useState("null");
	const [show, setShow] = useState(false);
	const [modalID, setModalID] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingBulkPublish, setLoadingBulkPublish] = useState(false);
	const [eanFilter, setEanFilter] = useState(null);
	const [titleFilter, setTitleFilter] = useState(null);

	const handleDetail = (id) => {
		setShow(true);
		setModalID(id);
	};

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar el producto?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación del producto se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handlePause = (key) => {
		confirm({
			title: "¿Seguro que quiere pausar el producto?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Pausar el producto se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PAUSED");
			},
		});
	};

	const handleActivate = (key, nombre, precio) => {
		confirm({
			title: `¿Seguro que quiere publicar ${nombre} al precio de $${precio}?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La publicación del producto se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PUBLISHED");
			},
		});
	};

	const handleAction = async (key, state) => {
		setLoading(true);
		const _state = { id: key, state: state };
		await update_product_state(_state);
		fetch_products_header();
		fetch_products(1, "");
		setLoading(false);
	};

	const fetch_products = async (page, search) => {
		const res = await get_products_page(search, state, page);
		setData(res);
		setEanFilter(
			res.items.map((r) => ({
				text: r.ean13,
				value: r.ean13,
			}))
		);
		setTitleFilter(
			res.items.map((r) => ({
				text: r.title + " " + r.presentation.name,
				value: r.title + " " + r.presentation.name,
			}))
		);
	};

	const fetch_products_header = async () => {
		const res = await get_products_header_info();
		setHeaderInfo(res);
	};
	useEffect(() => {
		fetch_products_header();
	}, [state]);

	useEffect(() => {
		fetch_products(1, "");
	}, [state]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_products(1, e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const update_all = async () => {
		setLoadingBulkPublish(true);
		try {
			const res = await update_masive_product_state("null");
			fetch_products_header();
			fetch_products(1, "");
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Productos creado con exito",
				"Los productos se crearon con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(TYPE.ERROR, err?.response?.data?.title);
		} finally {
			setLoadingBulkPublish(false);
		}
	};

	const menuActive = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/products/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handlePause(key.id)}>Pausar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuFuture = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/products/${key.id}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: <Typo onClick={() => handlePause(key.id)}>Pausar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuPaused = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/products/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuDraft = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/products/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link to={`/admin/products/${key.id}?uri=${uri}`}>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
			]}
		/>
	);

	const columns = [
		{
			title: (algo) => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.White }} />
					</div>
				);
			},
			dataIndex: "photoUrl",
			key: "photoUrl",
			width: 100,
			align: "center",
			render: (value, record, index) => (
				<div key={`${value}`}>
					<Image
						width={40}
						height={40}
						src={value}
						alt={record.title}
						onClick={() => handleDetail(record.id)}
					/>
					{modalID === record.id && show === true ? (
						<Modal
							visible={show}
							onOk={handleOk}
							onCancel={handleCancel}
							width={900}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<ProductModal
								id={record.id}
								minQuantity={record.minQuantity}
								maxQuantity={record.maxQuantity}
							/>
						</Modal>
					) : null}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "title",
			ellipsis: true,
			width: 300,
			render: (value, record) => (
				<Link
					to={`/admin/products/${key.id}?uri=${uri}`}
					onMouseOver={() => setKey(record)}
				>
					<div
						style={{
							color: COLORS.Default,
							fontWeight: "600",
							margin: "0",
							padding: "0",
						}}
					>
						<UnP>
							{value}{" "}
							<Typo type="muted" level={5}>
								{record?.presentation?.name}
							</Typo>
						</UnP>
					</div>
					<div
						style={{
							position: "relative",
							top: "-5px",
						}}
					>
						<Typo type="muted" texto="light" fontSize={12}>
							{record.brand
								? record.brand.name
								: value.length > 30
								? value.slice(0, 35) + "..."
								: value}
						</Typo>
					</div>
				</Link>
			),
			width: "350px",
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean</div>,
			dataIndex: "ean13",
			filters: eanFilter,
			onFilter: (value, record) => record.ean13.includes(value),
			filterSearch: true,
			render: (fila) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>{fila}</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			sorter: (a, b) => a.price - b.price,
			render: (fila, record, index) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>
					{record.offerPrice !== fila ? (
						<>
							<Line>${fila}</Line>
							<OfferPrice>${record.offerPrice}</OfferPrice>
						</>
					) : (
						<Price>${fila}</Price>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "price",
			sorter: (a, b) => a.price - b.price,
			render: (value, record, index) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>
					<Typo level={5} type="green">
						{record.offerPrice !== value
							? `${numberWithDotAndCommas(
									(100 - (record.offerPrice * 100) / value).toFixed(2)
							  )}%`
							: ""}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Categorías</div>,
			dataIndex: "categories",
			render: (fila) =>
				fila?.length > 1 ? (
					<Typo type="muted">{`${fila[0]?.name}, . . .`}</Typo>
				) : fila?.length === 1 ? (
					<Typo type="muted">{`${fila[0]?.name}`}</Typo>
				) : (
					fila?.length === 0 && ""
				),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			width: 50,
			align: "center",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					{
						<Dropdown
							overlay={
								state === "PUBLISHED"
									? menuActive
									: state === "PAUSED"
									? menuPaused
									: state === "DRAFT"
									? menuDraft
									: state === "FUTURE"
									? menuFuture
									: menuDeleted
							}
							placement="bottomRight"
						>
							<ActionButton onMouseOver={() => setKey(r)}>
								<BsThreeDotsVertical />
							</ActionButton>
						</Dropdown>
					}
				</>
			),
		},
	];

	const handleBackToTheGuide = () => {
		updateStartGuideProgress(user.uid, 2);
		navigate("/startGuide");
	};

	return (
		<Main style={{ padding: 0 }}>
			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<>
							<FiltersLeft>
								<Space size="middle">
									<AntdSearch
										allowClear
										placeholder="Buscar productos"
										onChange={debouncedEventHandler}
										width={400}
									/>
									<DropShadow type="drop">
										<SignInTemplate.AntdButton
											type="primary"
											bg="Primary"
											color="White"
											icon={
												<AiOutlinePlusCircle
													style={{
														top: 2,
														position: "relative",
														marginRight: "5px",
													}}
												/>
											}
											onClick={() =>
												navigate(`/admin/products/create?uri=${uri}`)
											}
											style={{ fontWeight: "600" }}
											width={220}
											height={48}
											fontSize={12}
										>
											NUEVO PRODUCTO
										</SignInTemplate.AntdButton>
										{userdata?.guideProgress === 1 &&
											data?.items?.length === 0 && (
												<Arrow>
													<FaArrowLeft />
												</Arrow>
											)}
									</DropShadow>
								</Space>
							</FiltersLeft>
						</>
					)}
				</Filters>
				<SpanCointainer>
					<Span onClick={() => setState("PUBLISHED")}>
						Publicados ({headerInfo?.totalPublished})
					</Span>
					<Span onClick={() => setState("FUTURE")}>
						Futuros ({headerInfo?.totalFuture})
					</Span>
					<Span onClick={() => setState("PAUSED")}>
						Pausados ({headerInfo?.totalPaused})
					</Span>
					<Span onClick={() => setState("DRAFT")}>
						Borrador ({headerInfo?.totalDraft})
					</Span>
					<Span onClick={() => setState("DELETED")}>
						Eliminados ({headerInfo?.totalDeleted})
					</Span>
					{state === "DRAFT" && (
						<Button
							onClick={update_all}
							style={{ marginRight: "60px" }}
							loading={loadingBulkPublish}
						>
							Publicar Todos
						</Button>
					)}
					<Span
						onClick={() => navigate(`/admin/prices?uri=${uri}`)}
						style={{ textDecoration: "underline" }}
					>
						Lista de Precios
					</Span>
					{/* {userdata?.guideProgress === 1 && (
						<SignInTemplate.AntdButton
							type="primary"
							bg="Primary"
							color="White"
							icon={<QuestionCircleOutlined />}
							onClick={handleBackToTheGuide}
							style={{
								width: "250px",
								fontWeight: "400",
							}}
						>
							Volver a la Guia de Inicio
						</SignInTemplate.AntdButton>
					)} */}
				</SpanCointainer>
				{/* {userdata?.guideProgress === 1 && data?.items?.length > 0 && (
					<ArrowRight>
						<FaArrowRight />
					</ArrowRight>
				)} */}
				<DataTable
					data={data}
					fetch={fetch_products}
					columns={columns}
					loading={loading}
				/>
			</Main.Body>
		</Main>
	);
}
